<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="4">
                        Μονάδες Χρέωσης
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="search"
                            :loading="loading"
                            :search-input.sync="search"
                            menu-props="auto"
                            label="Αναζήτηση Μονάδων Χρέωσης"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="4" align="end">
                        <v-btn v-if="$store.getters.getRole === 'ADMIN'" class="my-2 mr-2" dark small color="black" @click="createChargeUnit">
                            <v-icon dark>mdi-plus</v-icon>Καταχώριση
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <array-view
            :items="chargeUnits"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
            @onDelete="onDelete"
        />
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import chargeUnitService from '@/services/chargeUnit'
    import ArrayView from '@/components/ArrayView'
    import Alert from '@/components/Alert'

    export default {
        name: 'ChargeUnit',
        components: { ArrayView, Alert },
        data: () => {
            return {
                loading: false,
                successfullImport: null,
                chargeUnits: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Τίτλος',
                        value: 'title'
                    },
                    {
                        text: 'Κωδικός',
                        value: 'code'
                    },
                    {
                        text: 'Ημερομίσθιο',
                        value: 'wage'
                    },
                    {
                        text: 'Μονάδα χρέωσης πατέρας',
                        value: 'parent',
                        subLevel: 'title'
                    }
                ],
                search: null,
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1,
                showAlert: false,
                message: null,
                type: null
            }
        },
        watch: {
            search() {
                if (this.search) {
                    this.fetchFilteredChargeUnits()
                } else {
                    this.fetchAllChargeUnits()
                }
            },
            page() {
                var path = `/chargeUnits?page=${this.page}&size=${this.size}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
            },
            size() {
                var path = `/chargeUnits?page=${this.page}&size=${this.size}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
            this.fetchAllChargeUnits()
        },
        methods: {
            fetchFilteredChargeUnits() {
                this.loading = true
                chargeUnitService.getFilteredChargeUnits(this.search, this.page, this.size).then(resp => {
                    this.chargeUnits = resp.data.content
                    this.totalPages = resp.data.totalPages
                    this.loading = false
                })
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            paginationChange(page, size) {
                this.size = size
                this.page = page
                if (this.search) {
                    this.fetchFilteredChargeUnits()
                } else {
                    this.fetchAllChargeUnits()
                }
            },
            handleClick(action, item) {
                this.$router.push(`/chargeUnits/${item.id}/${action}?page=${this.page}&size=${this.size}`)
            },
            createChargeUnit() {
                this.$router.push(`/chargeUnits/create?page=${this.page}&size=${this.size}`)
            },
            fetchAllChargeUnits() {
                chargeUnitService.getChargeUnits(this.page, this.size).then(resp => {
                    this.chargeUnits = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            onDelete(chargeUnit) {
                chargeUnitService.deleteChargeUnit(chargeUnit.id)
                    .then(() => this.$router.go())
                    .catch((error) => {
                        this.setAlert(error.response.data.message, 'error')
                    })
            }
        }
    }
</script>

<style scoped>

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
